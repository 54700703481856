var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Test Archives")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.archives,"expanded":_vm.expanded,"item-key":"filename","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.children.filter(function (c) { return c.file_type == 'RNX'; }).length)+" ")]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.downloadArchive(item)}}},[_vm._v(" Download ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"2px"},attrs:{"colspan":headers.length}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Sample ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Result ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Date ")])])]),_c('tbody',_vm._l((item.children),function(child,i){return (child.file_type == 'RNX')?_c('tr',{key:i},[_c('td',[_vm._v(_vm._s(child.test_result.sample_id))]),_c('td',[_vm._v(_vm._s(child.test_result.result))]),_c('td',[_vm._v(_vm._s(child.test_result.created_at))])]):_vm._e()}),0)]},proxy:true}],null,true)})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }