
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readTestSummary, readTraces } from '@/store/tests/getters';
import { readToken } from '@/store/main/getters';
import { dispatchGetTestSummary, dispatchGetTraces, dispatchMakeArchive } from '@/store/tests/actions';

import { commitSetTestSummary } from '@/store/tests/mutations';
import { IFile } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

import { dispatchCheckApiError } from '@/store/main/actions';
import { commitSetFileNotifications } from '@/store/main/mutations';
import FileSaver from 'file-saver';



// instrument, module, cart, gx_username, status, result, start_date, reagent_lot, assay, upload_date

@Component
export default class Files extends Vue {

  public archives: IFile[] = [];

  public expanded: object[] = [];

  public headers: object[] = [
    { text: 'Filename', value: 'filename' },
    { text: 'Size', value: 'file_size' },
    { text: 'Count', value: 'count' },
    { text: 'Uploaded On', value: 'upload_date' },
    { text: 'Download', value: 'download' },
    { text: '', value: 'data-table-expand' },
  ];

  public async loadArchives() {
    const response = await api.getArchiveFiles(readToken(this.$store));
    if (response) {
      console.log('Loaded archives');
      this.archives = response.data;
    }
  }

  public async downloadArchive(file: IFile) {
      const response = await api.downloadFile(
        readToken(this.$store),
        file.id,
      );
      if (response) {
        console.log('Read file');
        console.log(response);
        FileSaver.saveAs(response.data, file.filename);
      }
  }

  public async mounted() {
    commitSetFileNotifications(this.$store, 0);
    await this.loadArchives();
    // dispatchGetTests(this.$store, this.selectedFilters);
  }

}
